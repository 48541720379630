//
// Main Navigation
//
nav.main{
  border-bottom: 1px solid @light-grey;
  text-transform: uppercase;
  overflow: hidden;
  width:100%;
  background: #FFF;
  z-index: 100;

  ul{
    margin: 1rem;

    li{
      margin-right: 5.5rem;
      &.current-menu-item a{
        color: @deborah-blue;
        &:after{
          width: 100%;
        }
      }
    }
  }

  a{
    text-decoration: none;
    transition-duration: 0.2s;
    color: @dark-grey;

    &:extend(.cmn-t-underline all);
  }

  .search-form {
    position: absolute;
    padding: 1rem;
    left: 0;
    right: 0;
    top: 0;
    height: 22px;
    background: #fff;
    text-align: left;
    z-index: 3;
    display: none;
    border-bottom: 2px solid white;

    form{
      & > div{
        position: relative;
      }

      input.search-input {
        padding: 0 35px 0 0;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        border: none;
        width: 100%;
        z-index: 3;
        color: #313131;
        height: 22px;
        line-height: 22px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        background: none;
        font-weight: normal;
        letter-spacing: 1px;
        font-size: 13px;
      }

      a.close-search {
        position: absolute;
        width: 22px;
        height: 22px;
        display: inline-block;
        right: 0;
        top: 0;
        z-index: 5;
        text-align: center;
        line-height: 22px;
        font-size: 16px;
        cursor: pointer;
        color: #313131;
      }
    }
  }
}

.mobile-menu{
  display:none;
}

@media (max-width: 40em){
  nav.main{
    position: relative;
    transition: height 300ms;

    ul{
      margin: 0;
    }

    ul li{
      width:100%;
      margin:0 0 1em 0;

      &:first-child{
        margin-top:1em;
      }
    }

    &.search-displayed{
      overflow:hidden;
      height: 42px;

      .menu-navigation-container{
        display: none;
      }
    }

    .search-form{
      height: 42px;
      display:none;
      padding:0;

      form{
        input.search-input{
          padding: 0 45px 0 0;
          margin: 10px;
        }
        a.close-search{
          right:10px;
          top: 10px;
        }
      }
    }

    .main-menu,.mobile-hidden{
      display:none;
    }
  }

  .mobile-menu{
    height: 42px;
    display:block;
    border-top: 1px solid #efefef;

    a{
      padding: 0.5em;
      height:42px;
      text-align: center;
      &:after{
        display:none;
      }

      &:first-child{
        border-right: 1px solid #efefef;
      }
    }
  }

}

@media (min-width: 40em) and (max-width: 64em){
  nav.main{
    ul li{
      margin-right: 2.5rem;
    }
  }
}


//
//
//

body.admin-bar nav.main{
  margin-top:32px;
}