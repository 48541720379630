html,body{
  font-family: hk_grotesklight,sans-serif;
  background: #FFF;
  color: @dark-grey;
  margin: 0; padding: 0;
}

body{
  padding-top: 3rem;
}

@media (max-width: 40em){
  body{
    padding-top:0;
  }
}

.serif{
  font-family: playfair_displaybold, serif;
}

strong,b{
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

a{
  color: @darker-blue;
  &:hover{
    color: @gold;
  }
}

p{
  line-height: 1.35rem;
}

.smaller-text{
  font-size: 0.9rem;
}

.grey-border-bottom{
  border-bottom: 1px solid @dark-grey;
}

.blue-border-bottom{
  border-bottom: 1px solid @darker-blue;
}

// Screen reader only
.sr-only, .screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

a.icon{
  text-decoration: none;
}

.debbie{
  width: 100%;
  height: 207px;
  background: url('../images/debbie.jpg') no-repeat;
  background-size: cover;
}

.max-width, .max-width-4 {
  max-width: 75rem;
}

.yt-thumbnail{
  overflow: hidden;
  img{
    max-width: 100%;
  }
}