.post-list{
  a{
    text-decoration: none;
  }

  .meta{
    color: @medium-grey;
  }
}

.content-tile{
  color: @medium-grey;

  a {
    text-decoration: none;
  }

  //h1{
  //  text-transform: uppercase;
  //  color: @dark-grey;
  //  height: 40px;
  //}

  .meta{
    font-size: .75rem;
    font-weight: bold;
  }

  .extract{
    line-height: 1.6rem;
    height: 85px;
    overflow: hidden;
  }

  .more{
    text-transform: uppercase;
    font-size: 0.8rem;
    height: 35px;
    line-height: 35px;
    width: 130px;
    color: @darker-blue;
  }

  &.big .table{
    height:400px;


    .text-container{
      height: 400px;
    }

    .featured-image{
      height:100%;
    }
  }

  &.sml h1{
    font-size: 1em;
  }


  a.title{
    text-transform: uppercase;
    color: #404040;
    height: 40px;
    margin: 1em 0;
    display: block;

    h1{
      display: inline;
      border-bottom: 1px solid transparent;
    }
  }


  &:hover{
    a.title h1{
      transition: border-bottom-color 0.25s ease-in-out;
      border-bottom: 1px solid #000;
    }
    a.featured-image .meta-category{
      background-color: rgba(0,0,0,0.6);
      color: #FFF;
      transition: all 0.25s ease-in-out;
    }
  }
}

.widget{
  height: 370px;
}

@media (max-width: 40em){

  .widget{
    height: auto;
  }

  .content-tile.big .table{
    .thumb-container{
      display: table-row;
      height: 250px;
    }
    .text-container{
      vertical-align: top;
      height: auto;
    }
  }
}

li.related-tile{
  text-align: center;
  margin: 0;
  padding: 1em;

  a{
    text-decoration: none;
    font-size: 0.8em;
    height: 230px;
    display:block;
    overflow: hidden;
    color: #2c2c2c;

    h3{
      font-family: Droid Sans,sans-serif;
      //color: #313131;
      margin-bottom: 0;
      text-transform: uppercase;
      font-size: 1.0em;
      height: 30px;
    }

    p{
      font-family: Droid Serif,serif;
      color: #9e9e9e;
      margin-top: 0.5em;
    }

    div{
      max-width: 100%;
      transition: all 0.3s;
    }
  }
}

// todo make this the same for .categories.tile.a
.tile-thumbnail{
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display:block;
  height:150px;
}

li.categories-tile{
  border: 1rem solid white;
  margin: 0;
  padding: 0;

  &:hover{
    .meta-category{
      background-color: rgba(0,0,0,0.6);
      color: #FFF;
      transition: all 0.25s ease-in-out;
    }
  }
}

.featured-image{
  display: block;
  background-size: cover;
  width: 100%;
  height: 250px;
  position: relative;
}


.meta-category{
  position: absolute;
  left: 10px;
  top: 10px;
  background: rgba(255,255,255,0.7);
  padding: 4px;
  font-size: 0.65em;
  color: black;
  text-transform: uppercase;
}