section{
  header{
    text-transform: uppercase;

    &:after{
      content: "";
      height: 1px;
      width: 100%;
      background: @darker-blue;
      display: block;
      margin-top:0.3rem;
    }
  }
}

#instagram{
  height: 136px;
  overflow: hidden;

  a{
    display: block;
    float:left;
    position: relative;
    height: 136px;
    width: 136px;
    border: 5px white solid;
    border-top: none;
    border-bottom: none;

    img{
      width:100%;
      height: 100%;
    }

    &:before{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 1px solid #FFF;
      opacity: 0;
      transform: scale(1,1);
      transition: all .1s ease-in-out;
    }

    &:hover:before{
      left: 10px;
      right: 10px;
      top: 10px;
      bottom: 10px;
      opacity: 1;
      cursor: pointer;
    }
  }
}

.list-inline{
  &:extend(.clearfix all);
  display:block;
  li{

    display:inline-block;
    margin-right: 1rem;

    &:last-child{
      margin-right: 0;
    }
  }
}

// http://callmenick.com/post/simple-css3-transitions-transforms-animations-compilation
// Underline CSS3 animation
.cmn-t-underline {
  position: relative;
  &:after {
    display: block;
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: @deborah-blue;
    content: "";
    transition: width 0.2s;
  }

  &:hover {
    color: @deborah-blue;
    &:after {
      width: 100%;
    }
  }
}

.basicBox {
  display: inline-block;
  text-align: center;
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
  svg rect, svg path, svg polyline {
    fill: none;
    stroke: @gold;
    stroke-width: 1;
  }

  svg rect {
    stroke-dasharray: 400, 0;
    transition: all 0.8s ease-in-out;
  }

  &:hover svg rect {
    stroke-width: 3;
    stroke-dasharray: 35, 245;
    stroke-dashoffset: 70;
    transition: all 0.8s ease-in-out;
  }
}