@import url(https://fonts.googleapis.com/css?family=Droid+Serif:400,700|Droid+Sans);

@font-face {
  font-family: 'hellodeboarh';
  src: url('../fonts/hellodeboarh.eot?89038635');
  src: url('../fonts/hellodeboarh.eot?89038635#iefix') format('embedded-opentype'),
  url('../fonts/hellodeboarh.woff2?89038635') format('woff2'),
  url('../fonts/hellodeboarh.woff?89038635') format('woff'),
  url('../fonts/hellodeboarh.ttf?89038635') format('truetype'),
  url('../fonts/hellodeboarh.svg?89038635#hellodeboarh') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hk_grotesklight';
  src: url('../fonts/hkgrotesk-light-webfont.eot');
  src: url('../fonts/hkgrotesk-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/hkgrotesk-light-webfont.woff2') format('woff2'),
  url('../fonts/hkgrotesk-light-webfont.woff') format('woff'),
  url('../fonts/hkgrotesk-light-webfont.ttf') format('truetype'),
  url('../fonts/hkgrotesk-light-webfont.svg#hk_grotesklight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'playfair_displaybold';
    src: url('../fonts/playfairdisplay-bold-webfont.eot');
    src: url('../fonts/playfairdisplay-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/playfairdisplay-bold-webfont.woff2') format('woff2'),
         url('../fonts/playfairdisplay-bold-webfont.woff') format('woff'),
         url('../fonts/playfairdisplay-bold-webfont.ttf') format('truetype'),
         url('../fonts/playfairdisplay-bold-webfont.svg#playfair_displaybold') format('svg');
    font-weight: normal;
    font-style: normal;

}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'hellodeboarh';
    src: url('../font/hellodeboarh.svg?89038635#hellodeboarh') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "hellodeboarh";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-search:before { content: '\e800'; } /* '' */
.icon-heart:before { content: '\e801'; } /* '' */
.icon-heart-empty:before { content: '\e802'; } /* '' */
.icon-bloglovin:before { content: '\e803'; } /* '' */
.icon-instagram:before { content: '\e804'; } /* '' */
.icon-pinterest:before { content: '\e805'; } /* '' */
.icon-left-open-outline:before { content: '\e806'; } /* '' */
.icon-right-open-outline:before { content: '\e807'; } /* '' */
.icon-arrows-cw:before { content: '\e808'; } /* '' */
.icon-food:before { content: '\e809'; } /* '' */
.icon-youtube:before { content: '\e80a'; } /* '' */
.icon-down-open-mini:before { content: '\e80b'; } /* '' */
.icon-vkontakte:before { content: '\e80c'; } /* '' */
.icon-cancel:before { content: '\e80d'; } /* '' */
.icon-twitter:before { content: '\f058'; } /* '' */