article.post{
  h1,h2,h3,h4,h5,h6{
    font-family: playfair_displaybold, serif;
  }
}

.article-header{
  h1,h2,h3,h4,h5,h6{
    letter-spacing: 4px;
  }
}

.article-next-previous{
  border-top:1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.page-nav{
  a{
    color: #2c2c2c;
    text-decoration: none;

    &:hover{
      font-weight: bold;

    }
  }
}

.article-body, .article-comments{
  font-size: 1em;

  a{
    color: @deborah-blue;
    text-decoration: none;
    position: relative;

    &:after{
      display: block;
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 0;
      height: 1px;
      background-color: @deborah-blue;
      content: "";
      transition: width .2s;
    }

    &:hover:after{
      width:100%;
    }

  }

  h1,h2,h3,h4,h5,h6{
    letter-spacing: 1px;
  }

  img{
    max-width: 100%;
    height: auto;
  }

  &>*, .tiled-gallery {
    margin-left: 16.66%;
    margin-right: 16.66%;
    line-height: 1.7em;
    text-align: justify;
    text-align-last: left;
  }

  p.full-width{
    margin: 2rem 0;
    text-align: center;
    text-align-last: center;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    margin: 2rem 0;
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
  }

}
