@import (inline) "../../node_modules/basscss/css/basscss.css";
@import (inline) "../../node_modules/basscss-btn/css/btn.css";
@import "./_fonts";

@import "./common/_variables";
@import "./common/_global";
@import "./components/_buttons";
@import "./components/_comments";
@import "./components/_wp-classes";

@import "./layouts/_header";
@import "./layouts/_footer";
@import "./layouts/_pages";
@import "./layouts/_posts";
@import "./layouts/_homepage";

//
// Move elsewhere below
//


// iPad in landscape
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {

  .featured-image{
    height: 180px;
  }

  #instagram{
    height: 114px;

    a{
      height: 114px;
      width: 114px;
    }
  }
}

.navigation.posts-navigation{
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;

  .nav-links{
    display: block;
    zoom: 1;

    a{
      text-transform: uppercase;
      font-size: 0.9em;
      text-decoration: none;
    }

    &:after{
      content: " ";
      display: block;
      font-size: 0;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }

  .nav-previous{
    float:right;
    position: relative;
    &:after{
      content: "\00bb";
      display: block;
      position: absolute;
      right:-10px;
      top: 0
    }
  }
  .nav-next{
    float:left;
    position: relative;
    &:after{
      content: "\00ab";
      display: block;
      position: absolute;
      left:-10px;
      top: 0
    }
  }
}

// iPad in portrait
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {

  #instagram{
    height: 137px;

    a{
      height: 137px;
      width: 137px;
    }
  }

}
